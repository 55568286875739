import Model, { Request } from '../model'
import Enum from '../enums'

export default class Course extends Model {
  static modelName () {
    return 'course'
  }

  objectID () {
    return this.idCourse
  }

  relations () {
    return {
      
    }
  }

  runs () {
    const url = this.constructor.modelBaseURL() + '/runs?id=' + this.objectID()

    return this.requestList(Request.get(url), CourseRun)
  }
}

export class CourseRun extends Model {
  static modelName () {
    return 'courseRun'
  }

  objectID () {
    return this.idCourseRun
  }

  relations () {
    return {
      
    }
  }
}
